body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,#site,#root{
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#map{

  transition: width 0.2s;
}
#site{
  transition: width 0.2s;
background: rgb(252,197,20);
background: linear-gradient(45deg, rgba(252,197,20,1) 0%, rgba(252,220,51,1) 50%, rgba(252,197,20,1) 100%);
}

